import './App.css';
import React, { lazy, Suspense } from "react";
import ReactGA from 'react-ga';
import CacheBuster from 'react-cache-buster';
import appversion from '../package.json';
// import Loading from './loading';
const Navbar = lazy(() =>
  import("./components/Navbar")
);
const Header = lazy(() =>
  import("./components/Header")
);
const About = lazy(() =>
  import("./components/About")
);
const Contact = lazy(() =>
  import("./components/ContactUs")
);
const SubFooter = lazy(() =>
  import("./components/SubFooter")
);
const Footer = lazy(() =>
  import("./components/Footer")
);
// function App() {
  const App = () => {
    const appVersion = appversion.version;

  ReactGA.initialize('UA-131344030-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    //  loadingComponent={<Loading />} //If not passed, nothing appears at the time of the new version check.
        >
       
    </CacheBuster>
    <Suspense fallback={<div />}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<div />}>
        <Header />
      </Suspense>
      <Suspense fallback={<div />}>
        <About />
      </Suspense>
      <Suspense fallback={<div />}>
        <Contact />
      </Suspense>
      <Suspense fallback={<div />}>
        <SubFooter />
      </Suspense>
      <Suspense fallback={<div />}>
        <Footer />
      </Suspense>
     {/* <Navbar />
      <Header />
      <About />
      <Contact />
      <SubFooter />
      <Footer /> */}
    </>
  );
}

export default App;
